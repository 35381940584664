import React from 'react';
import { connect } from 'dva';

import {
  Switch,
  Pagination,
  Modal,
  Input
} from 'antd';

import moment from 'moment';
import intl from 'react-intl-universal';

import {
  getAliExpressUrl,
  replaceUrlToHttps,
  getLanguage
} from 'utils/utils';
import { confirmLanguage } from '../../common/sysconfig';

import styles from './Index.less';

@connect(({ fulfillmentcenter, loading, login, global }) => ({
  userInfo: login.userInfo,
  fulfillmentcenter,
  loading: loading.models.fulfillmentcenter,
  storeId: global.storeId,
  listLoading: loading.effects['fulfillmentcenter/list']
}))
export default class FulfillmentCenter extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSync: false,
      prods: []
    };
  }

  componentDidMount() {
    this.getList();
  }

  componentDidUpdate(prevProps) {
    const { storeId } = this.props;

    if (storeId !== prevProps.storeId) {
      // do something, 当 storeId 变化
      this.changeStore();
    }
  }

  currentPage = 1; // 当前页码
  pageSize = 10;
  total = 0;
  supplier_product_id = '';
  checked = false;
  purchase = {};
  currentLanguage = getLanguage();

  // 获取列表
  getList = () => {
    this.props.dispatch({
      type: 'fulfillmentcenter/list',
      payload: {
        data: {
          current: this.currentPage,
          page_size: this.pageSize
        },
        callback: d => {
          const { count = 0, prods = [], purchase = {} } = d.data || {};

          this.total = count;
          this.purchase = purchase;
          this.setState({
            prods
          });
        }
      }
    });
  };

  // 改变商店
  changeStore = () => {
    this.currentPage = 1;
    this.getList();
  };

  // 改变页数
  changePagination = page => {
    this.currentPage = page;
    this.getList();
  };

  // 每页条数
  onShowSizeChange = (current, size) => {
    this.pageSize = size;
    this.getList();
  };

  // switch 开关
  changeSwitch = (checked, supplier_product_id) => {
    const tip = {
      trueTitle: intl.get('fulfillment_center.activate'),
      trueDec: intl.get('fulfillment_center.activate_dec'),
      input: intl.get('fulfillment_center.input_confirm'),
      falseTitle: intl.get('fulfillment_center.deactivate'),
      falseDec: intl.get('fulfillment_center.deactivate_dec')
    };

    this.supplier_product_id = supplier_product_id;
    this.checked = checked;

    Modal.confirm({
      title: tip[`${checked}Title`],
      content: (
        <div>
          {tip[`${checked}Dec`]}
          <br />
          {tip.input}
          <br />
          <Input onChange={this.changeInput} className={styles.confirmInput} />
        </div>
      ),
      okText: intl.get('public.ok'),
      cancelText: intl.get('public.cancel'),
      onOk: () => {
        return new Promise((resolve, reject) => {
          if (this.inputConfirm != confirmLanguage[this.currentLanguage]) {
            reject();
            return;
          }
          this.inputConfirm = '';
          this.props.dispatch({
            type: 'fulfillmentcenter/status',
            payload: {
              data: {
                supplier_product_id: this.supplier_product_id,
                status: this.checked ? 2 : 3
              },
              callback: d => {
                const { success } = d.data || {};
                if (!success) {
                  this.checked = !this.checked;
                }
                resolve();
                this.setProdStatus();
              }
            }
          });
        });
      }
    });
  };

  inputConfirm = '';
  changeInput = e => {
    this.inputConfirm = e.target.value.replace(/\s+/g, '').toLocaleLowerCase();
  };

  // 设置开关状态
  setProdStatus = () => {
    const { prods } = this.state;

    prods.forEach(prod => {
      if (prod.supplier_product_id == this.supplier_product_id) {
        prod.loading = false;
        prod.status = this.checked ? 2 : 3;
      }
    });

    this.setState({
      prods: [...prods]
    });
  };

  // 距离开或关过去了多长时间
  calcOverTime = prod => {
    const { activation_at = 0, pause_at = 0 } = prod;

    return moment.unix() - Math.max(activation_at, pause_at) > 172800;
  };

  // 开关提示文案
  getOnOffTip = prod => {
    const { status } = prod;
    const diff = this.calcOverTime(prod);
    let v = '';

    if (status == 2) {
      v = intl.get('fulfillment_center.using_tip');
      if (!diff) {
        v = intl.get('fulfillment_center.close_request');
      }
    } else {
      v = intl.get('fulfillment_center.closeing');
      if (!diff) {
        v = intl.get('fulfillment_center.activate_request');
      }
    }

    return v;
  };

  render() {
    const { prods } = this.state;

    return (
      <>
        <div className={styles.box}>
          <h3 className={styles.fulfillmentcenter}>
            {intl.get('fulfillment_center.title')}
          </h3>
          <p className={styles.fulfillmentcenterTip}>
            {intl.get('fulfillment_center.dec')}
          </p>
          <ul className={styles.list}>
            {prods && prods.length ? (
              prods.map(item => {
                const purchase = this.purchase[item.supplier_product_id] || 0;
                return (
                  <li key={item.supplier_product_id}>
                    <img src={replaceUrlToHttps(item.image)} />
                    <div className={styles.product}>
                      <p className={styles.title}>
                        <a
                          href={getAliExpressUrl(item.supplier_product_id)}
                          target="_blank"
                          title={item.title}
                        >
                          {item.title}
                        </a>
                      </p>
                      <p className={styles.priceLine}>
                        <span>
                          {intl.get('product.product.price')}: ${' '}
                          {item.supplier_cost / 100}
                        </span>
                        <span>
                          {intl.get('product.product.stock')}:{' '}
                          {item.stock || 999}
                        </span>
                        <span>
                          {intl.get('fulfillment_center.pruchese')}: {purchase}
                        </span>
                        <span className={styles.saved}>
                          {intl.get('fulfillment_center.saved')}: ${' '}
                          {(item.origin_cost - item.supplier_cost) *
                            purchase /
                            100}
                        </span>
                      </p>
                    </div>
                    <div className={styles.tools}>
                      <div className={styles.switchBtn}>
                        <Switch
                          loading={item.loading}
                          checked={item.status == 2}
                          onChange={checked =>
                            this.changeSwitch(checked, item.supplier_product_id)
                          }
                        />
                      </div>
                      <p className={styles.switchTip}>
                        {this.getOnOffTip(item)}
                      </p>
                    </div>
                  </li>
                );
              })
            ) : (
              <li className={styles.empty}>
                {intl.get('product.manage_product.no_data')}
              </li>
            )}
          </ul>
          <Pagination
            hideOnSinglePage
            showSizeChanger
            total={this.total}
            pageSize={this.pageSize}
            current={this.currentPage}
            onChange={this.changePagination}
            onShowSizeChange={this.onShowSizeChange}
            className={styles.paginationWrap}
          />
        </div>
      </>
    );
  }
}
